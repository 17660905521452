import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const CultDiffCharactor = () => {
  return (  
    <section className="diff-char">
        <Container>
            <Row className="justify-content-center">
                <Col lg={7} className="text-center">
                    <p className="cs-sub-text video">By portraying these two different characters, we made sure the video addresses that the app caters to people with different fitness goals, both mental and physical.</p>
                </Col>
            </Row>
        </Container>
    </section>     
  )
}

export default CultDiffCharactor
