import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const CultColorCode = () => {
  return (  
    <section className="color-code">
        <Container>
            <Row>
                <Col lg={5} className="ctext-center mb-5 mb-lg-0">
                    <StaticImage src="../../../images/case-studies/cult-transform/color-code.png" alt="Cult transform Color code WowMakers" placeholder="blurred" className="img-fluid" width={648} height={557}/>
                </Col>
                <Col lg={6} className="offset-lg-1 d-flex flex-column">
                    <p className="cs-sub-text video mb-4 mb-lg-0">We did a great deal of exploration with the colors and did some playing around which was fun. But in the end, it was decided that the video will follow the brand colors. </p>
                    <p className="ms-lg-auto mt-lg-auto text-lg-right text-center">
                        <img src="/case-studies/cult-transform/pot-right-bottom.svg" className="img-fluid" alt="Bottom img"/>
                    </p>
                </Col>
            </Row>
        </Container>
    </section>     
  )
}

export default CultColorCode
