import React, { useEffect } from "react";
import { Link } from "gatsby"
import lottie from "lottie-web"
import CultHeroAnimation from "../../../images/case-studies/cult-transform/cult-hero-animation-data.json"
import { Col, Container, Row } from "react-bootstrap"

const CultMain = () => {
    
    useEffect(() => {
        const anim = lottie.loadAnimation({
          container: document.getElementById('animation-container'),
          renderer: "svg",
          loop: true,
          autoplay: true,
          animationData: CultHeroAnimation
        });
        return () => anim.destroy(); // optional clean up for unmounting
      }, []);    

    return (
        <div className="cs-header video">
            <Container>
                <div className="header-section-1 d-flex">
                    <Row className="align-items-center">
                        <Col md={7}>
                            <div className="csh-content video-case-study">
                                <p><Link to="/case-studies">Case Studies</Link> / <span className="text-dark">Cult Fit</span></p>
                                <h1 className="mw-100">Here's how we helped Cult Fitness reward fitness journeys with their habit-coaching program.</h1>
                                <img src="/case-studies/cult-transform/cult-transform-logo.svg" className="img-fluid" alt="cult-transform-logo" />
                            </div>
                        </Col>
                        <Col md={5}>
                            <div id="animation-container"></div>
                        </Col>
                    </Row>
                </div>
                <div className="header-section-2">
                    <Row className="justify-content-center">
                        <Col lg={10}>
                            <Row className="pb-2">
                                <Col lg={3} className="d-flex justify-content-center justify-content-lg-start">
                                    <div className="project-info text-center">
                                        <span>Client</span>
                                        <p>Cult.fit</p>
                                    </div>
                                </Col>
                                <Col lg={6} className="d-flex justify-content-center">
                                    <div className="project-info text-center">
                                        <span>INDUSTRY</span>
                                        <p>Fitness & Wellness</p>
                                    </div>
                                </Col>
                                <Col lg={3} className="d-flex justify-content-center justify-content-lg-end">
                                    <div className="project-info text-center">
                                        <span>GEOGRAPHY</span>
                                        <p>India</p>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="align-items-center pt-5">
                                <div className="col-lg-4 d-flex justify-content-center">
                                    <div className="cult-video">

                                        <div className="gatsby-resp-iframe-wrapper">
                                            <iframe
                                                width="366"
                                                height="540"
                                                src="https://www.youtube.com/embed/RjcfSArKkOI?controls=0"
                                                title="YouTube video player"
                                                frameborder="0"
                                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen
                                            ></iframe>
                                        </div>                                    
                                    </div>
                                </div>
                                <div className="col-lg-4 d-flex justify-content-center justify-content-lg-start order-lg-first py-5 py-lg-0">
                                    <div className="video-info text-center">
                                        <p className="title mb-0">2D Explainer</p>
                                        <p className="data mb-0">Video</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 d-flex justify-content-center justify-content-lg-end">
                                    <div className="video-info text-center">
                                        <p className="title mb-0">92</p>
                                        <p className="data mb-0">Seconds</p>
                                    </div>
                                </div>                                
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>    
    )
}

export default CultMain
