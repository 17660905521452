import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const CultDescription = () => {
  return (
    <section className="cult-description">
        <Container>
            <Row>
                <Col xs={12}>
                    <p className="cs-sub-text video">
                        When Cult Fitness, one of the most popular fitness and wellbeing service providers in town approached us to make a video for them, we were more than excited! Most of our team members had already experienced the amazing Cult Fitness transformation, and working for a brand that has helped us stay fit and resonated with was quite a fun experience.
                    </p>
                </Col>
            </Row>
        </Container>
    </section>           
  )
}

export default CultDescription
