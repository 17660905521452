import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const CultTestimonials = () => {
  return (  
    <section className="testimonials video">
        <Container>
            <Row>
                <Col xs={12}>
                    <div className="testimonials-details">
                        <Row className="justify-content-center">
                            <Col lg={10}>
                                <div className="client-details d-flex justify-content-center align-items-center">
                                    <div className="profile-pic text-end">
                                        <StaticImage src="../../../images/case-studies/cult-transform/charvi-kain-cult-fit.png" alt="Cult transform Testimonials WowMakers" placeholder="blurred" className="img-fluid" width={192} height={192}/>
                                    </div>
                                    <div className="info ml-lg-5 ml-3">
                                        <p className="name">Charvi Kain </p>
                                        <p className="designation">Growth & Business Strategy at Cure.Fit</p>
                                    </div>
                                </div>
                                <p className="cs-sub-text video text-center mt-5">“Worked with WowMakers on a product explainer video for curefit - would recommend for a professional, timely delivery of great explainer animated content!”</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>      
  )
}

export default CultTestimonials
