import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const CultTransformingStoryline = () => {
  return ( 
    <section className="transforming-storyline pb-0">
        <Container>
            <Row>
                <Col xs={12}>
                    <h2 className="sub-title">Transforming the storyline into a great visual experience.</h2>
                    <p className="cs-sub-text video">The video was animated with two stories narrated simultaneously in a fluid way. As the video was meant to be viewed inside the app, we followed the vertical video format in a specific dimension. The result was an animated story of the transformation of two characters with different goals, and their journey towards attaining them.</p>
                </Col>
            </Row>
        </Container>
    </section>         
  )
}

export default CultTransformingStoryline
