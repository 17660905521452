import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import CultVisualExpVideo from "../../../images/case-studies/cult-transform/yoga-session.mp4"
import { Col, Container, Row } from "react-bootstrap"

const CultVisualExp = () => {
  return (
    <section className="visual-exp">
        <Container fluid>
            <Row>
                <Col xs={12} className="col-12">
                    <Row>
                        <Col lg={3} className="text-center mb-4 mb-lg-0 ps-0 pe-0 pe-lg-1">
                            <StaticImage src="../../../images/case-studies/cult-transform/visual-exp-1.png" alt="Cult transform Visual Experience WowMakers" placeholder="blurred" className="img-fluid" width={455} height={670}/>
                        </Col>
                        <Col lg={3} className="text-center mb-4 mb-lg-0 ps-0 pe-0">
                            <StaticImage src="../../../images/case-studies/cult-transform/visual-exp-2.png" alt="Cult transform Visual Experience WowMakers" placeholder="blurred" className="img-fluid" width={455} height={670}/>
                        </Col>
                        <Col lg={3} className="text-center mb-4 mb-lg-0 ps-0 pe-0 ps-lg-1 pe-lg-0">
                            <StaticImage src="../../../images/case-studies/cult-transform/visual-exp-3.png" alt="Cult transform Visual Experience WowMakers" placeholder="blurred" className="img-fluid" width={455} height={670}/>
                        </Col>
                        <Col lg={3} className="text-center ps-0 pe-0 ps-lg-1">
                            <StaticImage src="../../../images/case-studies/cult-transform/visual-exp-4.png" alt="Cult transform Visual Experience WowMakers" placeholder="blurred" className="img-fluid" width={455} height={670}/>
                        </Col>                                                
                    </Row>
                </Col>
            </Row>
            <Row className="pt-lg-5 pt-4">
                <Col xs={12} className="px-0 pb-lg-5 pb-4">
                    <StaticImage src="../../../images/case-studies/cult-transform/full-with-image-bottom.png" alt="Cult transform Visual Experience WowMakers" placeholder="blurred" className="img-fluid" width={1919} height={617}/>
                </Col>
                <Col xs={12} className="px-0">
                    <video width="1920" height="1080" className="vid" autoPlay={true} loop muted autoplay playsinline>
                        <source src={CultVisualExpVideo} type="video/mp4" />
                    </video>                
                </Col>                
            </Row>
        </Container>
    </section>          
  )
}

export default CultVisualExp
