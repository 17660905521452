import React from "react"
import CultFeatureVideo1 from "../../../images/case-studies/cult-transform/feature-01.mp4"
import CultFeatureVideo2 from "../../../images/case-studies/cult-transform/feature-02.mp4"
import CultFeatureVideo3 from "../../../images/case-studies/cult-transform/feature-03.mp4"
import { Col, Container, Row } from "react-bootstrap"

const CultFeatures = () => {
  return (
    <section className="features pb-0">
        <Container>
            <Row>
                <Col  lg={3} className="mb-5 mb-lg-0">
                    <p className="cs-sub-text video">We also highlighted the different features and benefits that are in store for the users on the app, like the one that allows the user to chat with the habit coach.</p>
                </Col>
                <Col lg={3} className="text-center mb-4 mb-lg-0 px-lg-3 px-0">
                    <video width="298" height="438" className="vid" autoPlay={true} loop muted autoplay playsinline>
                        <source src={CultFeatureVideo1} type="video/mp4" />
                    </video>
                </Col>
                <Col lg={3} className="text-center mb-4 mb-lg-0 px-lg-3 px-0">
                    <video width="298" height="438" className="vid" autoPlay={true} loop muted autoplay playsinline>
                        <source src={CultFeatureVideo2} type="video/mp4" />
                    </video>                    
                </Col>
                <Col  lg={3} className="text-center px-lg-3 px-0">
                    <video width="298" height="438" className="vid" autoPlay={true} loop muted autoplay playsinline>
                        <source src={CultFeatureVideo3} type="video/mp4" />
                    </video>
                </Col>                                

            </Row>
        </Container>
    </section>           
  )
}

export default CultFeatures
