import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const CultMeetCharactor = () => {
  return (  
    <section className="meet-charactor">
        <div className="meet">
            <Container>
                <Row className="align-items-center">
                    <Col lg={6}>
                        <h2 className="sub-title">
                            Meet Ananya
                        </h2>
                        <p className="cs-sub-text video">Ananya has a demanding job and a busy schedule. To her, fitness means keeping herself sane and mindful to have that perfect balance between work and life.</p>
                    </Col>
                    <Col lg={6}>
                        <StaticImage src="../../../images/case-studies/cult-transform/meet-ananya.png" alt="Cult transform Charactor WowMakers" placeholder="blurred" className="img-fluid" width={824} height={533}/>                        
                    </Col>
                </Row>
            </Container>
        </div>
        <div className="meet meet-ananya">
            <Container>
                <Row className="row align-items-center">
                     <Col lg={6}>
                        <StaticImage src="../../../images/case-studies/cult-transform/meet-viraj.png" alt="Cult transform Charactor WowMakers" placeholder="blurred" className="img-fluid" width={787} height={668}/>          
                    </Col>                    
                    <Col lg={6}>
                        <h2 className="sub-title">
                            Meet Viraj
                        </h2>
                        <p className="cs-sub-text video">Viraj has been busy with too many parties and late-night hangouts. Realizing that this lifestyle is taking a toll on his health, he is now on a mission to build muscle power. For him, fitness is rigorous exercises and cardios. </p>
                    </Col>
                </Row>
            </Container>
        </div>        
    </section>      
  )
}

export default CultMeetCharactor
