import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const CultHowItWorks = () => {
  return (
    <section className="how-it-works">
        <Container>
            <div className="child-section">
                <Row>
                    <Col lg={3}>
                        <h2 className="sub-title">
                            Warming up to explain how the program works on the app
                        </h2>
                    </Col>
                    <Col lg={8} className="offset-lg-1">
                        <p className="cs-sub-text video">Keeping the pandemic and the busy schedules of prospective users in mind, Cult came up with something interesting - an app that offers a habit-based scientific coaching program. The app guarantees customized workout and diet plans that help people build habits fitting their schedule - all under the guidance of a habit coach. Our task was to make a video that would break down how the app works in the most interesting way.</p>
                    </Col>
                </Row>
            </div>
            <div className="child-section">
                <Row>
                    <Col lg={3}>
                        <h2 className="sub-title">
                            Doing the groundwork to tell an engaging story
                        </h2>
                    </Col>
                    <Col lg={8} className="offset-lg-1">
                        <p className="cs-sub-text video">People are distinct and come with varied fitness goals. On that note, Cult Fitness embraces inclusiveness and offers different workout plans to suit the varied requirements of the prospective users through Cult Transform. For this reason, we were aware that showing a single point of view may not work well for the brand. We thought that the best way to weave a story was to show the fitness journey of different characters with different goals.</p>
                    </Col>
                </Row>
            </div>            
        </Container>
    </section>            
  )
}

export default CultHowItWorks
