import React from "react"
import SeoHead from "../../components/global/seoHead"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"

import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import CultMain from "../../components/case-studies/cult-transform/cultMain"
import CultTestimonials from "../../components/case-studies/cult-transform/cultTestimonials"
import CultDescription from "../../components/case-studies/cult-transform/cultDescription"
import CultHowItWorks from "../../components/case-studies/cult-transform/cultHowItWorks"
import CultMeetCharactor from "../../components/case-studies/cult-transform/cultMeetCharactor"
import CultDiffCharactor from "../../components/case-studies/cult-transform/cultDiffCharactors"
import CultColorCode from "../../components/case-studies/cult-transform/cultColorCode"
import CultFeatures from "../../components/case-studies/cult-transform/cultFeatures"
import CultTransformingStoryline from "../../components/case-studies/cult-transform/cultTransformingStoryline"
import CultVisualExp from "../../components/case-studies/cult-transform/cultVisualExp"
import CultMoreCs from "../../components/case-studies/cult-transform/cultMoreCs"
import DirectContact from "../../components/global/directContact"

import csCultFeaturedImage from "../../../static/case-studies/cult-transform/cult-fit-fetured-img.jpeg"

import "../../sass/pages/case-studies/cs-global.scss"
import "../../sass/pages/case-studies/cult-transform.scss"

export const Head = () => (
  <>
    <SeoHead
      ogImage={csCultFeaturedImage}
      title="Cult Fit Case Study | 2D Animation Explainer Video | Fitness & Wellness"
      description="Read how we helped Cult Fitness promote their habit coaching program with an engaging explainer video"
    />
  </> 
)

const CultTransform = () => {
    return (
      <Layout>
        <Seo
          bid="cs-cult-transform"
          bclass="case-studies-page"
        ></Seo>
        <ScrollIndicationHeightZero />
        <CultMain />
        <CultTestimonials />
        <CultDescription />
        <CultHowItWorks />
        <CultMeetCharactor />
        <CultDiffCharactor />
        <CultColorCode />
        <CultFeatures />
        <CultTransformingStoryline />
        <CultVisualExp />
        <CultMoreCs />
        <DirectContact />
      </Layout>
    )
}
export default CultTransform
